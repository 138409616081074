<template>
  <div class="m-map">
    <div class="search">
      <el-input id="tipinput" placeholder="请输入关键字" v-model="searchKey">
        <!-- <el-button slot="append" @click="onhandelSearch">搜索</el-button> -->
      </el-input>
      <div v-show="searchKey" class="result"></div>
    </div>

    <div id="gaode_Map" class="map" style="width: 600px;height: 300px;">正在加载地图...</div>
  </div>
</template>

<script>

import AMapLoader from "@amap/amap-jsapi-loader";
window._AMapSecurityConfig = {
  // 设置安全密钥
  securityJsCode: "d8b1ff7fe3a09f476a302f1e7793722b",
};

export default {
  props: ['lat', 'lng'],
  data () {
    return {
      searchKey: '', // 搜索内容

      dataForm: {
        kqName: undefined,
        kqLocation: undefined,
        kqLongitude: undefined,
        kqLatitude: undefined,
        kqWorkUnit: undefined,
        cronkqAccredit: [],
        kqValidCardRange: undefined,
      },
      input: "",
      map: null,
      auto: null,
      placeSearch: null,
      lnglat: [],
      markers: [],
      position: {},
    }
  },

  methods: {

   // 地图初始化
   initMap (lng, lat) {

      let centerLen = [120.21551, 30.25308];
      
      if (lat && lng) {
        centerLen = [lng, lat]
      }

      AMapLoader.load({
        key: "ae14420f489d8ef3b9c172bcaeeb140c",
        version: "2.0",
        plugins: ["AMap.AutoComplete", "AMap.PlaceSearch", "AMap.Geocoder"],
      })
        .then((AMap) => {
          this.map = new AMap.Map("gaode_Map", {
            viewMode: "2D",
            zoom: 18,
            center: centerLen,
          });
          this.setMarker(centerLen)
          // 关键字查询
          this.searchMap();
          // 监听鼠标点击事件
          this.map.on("click", this.clickMapHandler);
        })
        .catch((e) => { 
          console.log(e, '错误信息');
          this.$message.error('加载地图失败');
        });
    },
    // 关键字查询
    searchMap () {
      // 搜索框自动完成类
      this.auto = new AMap.AutoComplete({
        input: "tipinput",
      });
      //构造地点查询类
      this.placeSearch = new AMap.PlaceSearch({
        map: this.map,
        pageSize: 10, // 单页显示结果条数
        pageIndex: 1, // 页码
        panel: 'panel', // 结果列表将在此容器中进行展示。
        autoFitView: true,
      });

      this.placeSearch.on('markerClick',  (e) => {

        console.log({
          lng: e.event.lnglat.getLng(),
          lat: e.event.lnglat.getLat(),
          address: e.data.address
        }, '222')

        this.$emit("drag", {
          lng: e.event.lnglat.getLng(),
          lat: e.event.lnglat.getLat(),
          address: e.data.address
        })
      });

      // 当选中某条搜索记录时触发
      this.auto.on("select", this.selectSite);
    },

    //选中查询出的记录
    selectSite (e) {
      if (e.poi.location) {
        this.placeSearch.setCity(e.poi.adcode);
        this.placeSearch.search(e.poi.name);

        console.log({
          lng: e.poi.location.lng,
          lat: e.poi.location.lat,
          formattedAddress: e.poi.district + e.poi.name
        }, '选择搜索出来的条目');

        this.$emit("drag", {
          lng: e.poi.location.lng,
          lat: e.poi.location.lat,
          address: e.poi.district + e.poi.name
        })
      } else {
        this.$message.error("查询地址失败，请重新输入地址");
      }
    },

    // 点击地图事件获取经纬度，并添加标记
    clickMapHandler (e) {
      this.dataForm.kqLongitude = e.lnglat.getLng();
      this.dataForm.kqLatitude = e.lnglat.getLat();
      this.lnglat = [e.lnglat.getLng(), e.lnglat.getLat()];
      this.setMarker(this.lnglat);
      // 点击地图上的位置，根据经纬度转换成详细地址
      let geocoder = new AMap.Geocoder({});
      let that = this;
      geocoder.getAddress(this.lnglat, function (status, result) {
        if (status === "complete" && result.regeocode) {

          that.$emit("drag", {
            lng: e.lnglat.getLng(),
            lat: e.lnglat.getLat(),
            address: result.regeocode.formattedAddress
          })
        }
      });

      this.position = {
        longitude: e.lnglat.getLng(),
        latitude: e.lnglat.getLat(),
        address: that.address,
      };
      this.searchKey = that.address; 
    },
    //  添加标记
    setMarker (lnglat) {
      this.removeMarker();
      let marker = new AMap.Marker({
        position: lnglat,
      });

      marker.setMap(this.map);

      this.markers.push(marker);
    },

    // 删除之前后的标记点
    removeMarker () {
      if (this.markers) {
        this.map.remove(this.markers);
      }
    },

  }
}
</script>

<style lang="scss">
.m-map {
  max-width: 500px;
  max-height: 300px;
  position: relative;
  .map {
    width: 100%;
    height: 100%;
  }
  .search {
    position: absolute;
    top: 10px;
    left: 10px;
    width: 285px;
    z-index: 999;
  }
  .result{
    max-height: 300px;
    overflow: auto;
    margin-top: 10px;
  }
}
</style>
